<template>
  <div>
    <head-layout head-title="我的培训" :head-btn-options="headBtnOptions" @head-add="addFn">
    </head-layout>

    <grid-head-layout ref="searchForm" :search-columns="searchColumns" v-model="searchForm"
                      @grid-head-search="searchChange" @grid-head-empty="searchReset"/>

    <grid-layout
      ref="gridLayOut"
      :tableOptions="tableOptions"
      :gridRowBtn="gridRowBtn"
      :table-data="tableData"
      :tableLoading="tableLoading"
      @grid-row-detail-click="toView"
      :data-total="page.total"
      :page="page"
      @page-size-change="onLoad"
      @page-current-change="onLoad"
      @page-refresh-change="onLoad"
      @gird-handle-select-click="handleSelectionChange"
      @grid-view="toSee"
      @grid-romve="toDel"
    >
      <template slot="trainTheme" slot-scope="{row}">
        <div style="text-align: center">
          {{ row.trainTheme }}
        </div>
        <!--          <div>-->
        <!--            {{row.trainStart}}-{{row.trainEnd}}-->
        <!--          </div>-->
      </template>

      <template #customBtn="{row}">
        <el-button
          v-if="row.status == 1 && row.trainWay == 3"
          type="text"
          size="small"
          @click="toSee(row)">
          签收
        </el-button>
        <el-button
          type="text"
          size="small"
          @click="view(row)">
          查看
        </el-button>

      </template>

    </grid-layout>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <span>{{ dialogtext }}</span>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="SureToExecute">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      :title="'评价'"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showDialog"
      width="60%"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="评价维度1">
          <el-rate v-model="form.evaluate"></el-rate>
        </el-form-item>
        <el-form-item label="评价说明">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="form.evaluateContent"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="closeDialog">取消</el-button>
        <el-button size="small" type="primary" @click="handleNodeSave"
        >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="我的培训"
      :modal-append-to-body="false"
      :visible.sync="videoShow"
      :before-close="videoHandleClose"
      :fullscreen="fullscreen"
      class="qmDialog"
      top="5vh"
      width="80%"
    >
      <div class="positionabs">
        <i class="el-icon-full-screen" @click="fullClick()"></i>
      </div>
      <VideoDialog v-if="videoShow" ref="VideoDialog"></VideoDialog>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDetailById,
  useTaskPage,
  trainingTaskUseStatusUpdate,
  evaluate,
  trainingUpdateSign
} from "@/api/safe/index";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import VideoDialog from "@/views/business/safetyenment/train/trainingTaskUseList/VideoDialog";

export default {
  components: {
    HeadLayout,
    GridLayout,
    VideoDialog
  },
  props: {},
  data() {
    return {
      taskCode: '',
      userId:"",
      deptId:"",
      detailRow: {},
      showDialog: false,
      videoShow: false,
      fullscreen: false,
      form: {
        evaluate: null,
        evaluateContent: null,
      },
      total: '',
      dialogtext: '',
      runState: "",
      btnType: '',
      page: {
        pageSize: PAGE_CONSTANT.TWENTY,
        currentPage: 1,
        total: 0
      },
      dialogVisible: false,
      tableLoading: false,
      ExecuteId: "",
      tableData: [],
      gridRowBtn: [],

      // headBtnOptions: [
      //    {
      //      label: '新增',
      //      emit: "head-add",
      //      type: "button",
      //      icon: "",
      //    },
      //  ],

      searchColumns: [
        {
          label: '',
          prop: "trainTheme",
          span: 4,
          placeholder: "名称",
        },
        // {
        //   label: '',
        //   prop: "deptName",
        //   span: 4,
        //   placeholder: "下发单位",
        // },
        {
          label: '',
          type: "select",
          prop: "status",
          span: 4,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_training_use_status",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dataType: "number",
          placeholder: "状态",
        },
      ],
      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 150,
        index: true,
        indexLabel: "序号",
        linklabel: "trainTheme",
        column: [
          {
            label: '名称',
            prop: "trainTheme",
            // slot:true,
            align: "left",
            cell: true,
            overHidden: true,
          },
          {
            label: '开始时间',
            prop: "trainStart",
            align: 'center',
            width: 240,
            cell: true,
            overHidden: true,
          },
          {
            label: '结束时间',
            prop: "trainEnd",
            align: 'center',
            width: 240,
            cell: true,
            overHidden: true,
          },

          // {
          //   label: '下发单位',
          //   prop: "deptName",
          //   align: 'left',
          //   cell: true,
          //   overHidden: true,
          // },
          {
            label: '参训时间',
            prop: "trainingDuration",
            align: 'center',
            width: 240,
            cell: true,
            overHidden: true,
          },
          {
            label: '培训方式',
            prop: "trainWay",
            align: 'center',
            dataType: "number",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=train_method",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            width: 90,
            cell: true,
            overHidden: true,
          },
          // {
          //   label: '是否考试',
          //   prop: "isExam",
          //   align: 'center',
          //   dataType: "number",
          //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_exam",
          //   props: {
          //     label: "dictValue",
          //     value: "dictKey"
          //   },
          //   width: 80,
          //   cell: true,
          //   overHidden: true,
          // },
          {
            label: '状态',
            prop: "status",
            cell: true,
            type: "select",
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_training_use_status",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            align: 'center',
            dataType: "number",
            width: 90,
          },
        ],
      },
    };
  },

  methods: {
    videoHandleClose() {
      this.$refs.VideoDialog.clearTime();
      this.videoShow = false;
      this.onLoad(this.page, {})
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page)
    },
    searchChange(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      if (this.taskCode === undefined && this.userId === undefined && this.deptId === undefined) {
        useTaskPage(
          page.currentPage,
          page.pageSize,
          Object.assign(params, this.query)
        ).then(res => {
          const data = res.data.data;
          this.page.total = data.total;
          this.$refs.gridLayOut.page.total = data.total;
          this.tableData = data.records;
          this.tableLoading = false;
        });
      } else {
        this.tableData = [];
        let dataList = {
          id:this.taskCode,
          deptId:this.deptId,
          userId:this.userId,
          status:params.status,
          trainTheme:params.trainTheme
        };
        console.log(dataList,"123546")
        getDetailById(dataList).then(res => {
          if(res.data.data){
            this.page.total = 1;
            this.$refs.gridLayOut.page.total = 1;
            this.tableData.push(res.data.data);
          }
          this.page.total = 0;
          this.$refs.gridLayOut.page.total = 0;
          // this.taskCode = undefined;
          this.tableLoading = false;
        })
      }
    },

    //关闭
    rowEdit(item) {
      if (item.id) {
        trainingTaskUseStatusUpdate({id: item.id, status: 4}).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "提交成功",
            });
            this.onLoad(this.page)
          }
          // this.$router.push("/business/safetyenment/train/trainingUseTaskPage");
        });
      }
    },
    assessment(row) {
      this.form = [];
      this.showDialog = true;
      this.detailRow = row
    },
    closeDialog() {
      this.showDialog = false;
      this.$refs.form.resetField();
    },
    handleNodeSave() {
      let params = {
        knowInfoId: this.detailRow.id,
        ...this.form,
      };
      console.log(params, 'params-------------------')
      evaluate({
        id: this.detailRow.id,
        evaluate: params.evaluate,
        evaluateContent: params.evaluateContent
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "评价成功",
          });
          this.onLoad(this.page)
        }
        this.showDialog = false;
        this.$refs.form.resetField();
        // this.$router.push("/business/safetyenment/train/trainingUseTaskPage");
      });
    },
    // 培训
    training(row) {
      // 检查行数据是否符合特定条件
      function isValidRow(row) {
        return (row.id && row.status === 2 && row.isSignIn !== 1) || (row.id && row.status === 2 && row.trainWay === 1);
      }

      // 更新任务状态
      function updateTaskStatus(row) {
        return trainingTaskUseStatusUpdate({id: row.id, status: 3});
      }

      // 处理任务状态更新结果
      function handleStatusUpdateResult(row, res) {
        if (res.data.code === 200) {
          this.onLoad(this.page);
        }
      }

      // 处理视频对话框的显示
      function handleVideoDialog(row) {
        this.videoShow = true;
        this.$nextTick(() => {
          console.log(this.$refs.VideoDialog, '426');
          this.$refs.VideoDialog.id = row.id;
          this.$refs.VideoDialog.getTrainCourseList();
        });
      }

      // 主要的业务逻辑
      if (isValidRow(row)) {
        updateTaskStatus(row)
          .then((res) => handleStatusUpdateResult(row, res))
          .catch((error) => {
            // 在这里处理错误
            console.error('Error updating task status:', error);
          });
      }
      this.videoShow = true;
      this.$nextTick(() => {
        console.log(this.$refs.VideoDialog, '426');
        this.$refs.VideoDialog.id = row.id;
        this.$refs.VideoDialog.getTrainCourseList();
      });
      // handleVideoDialog(row);

    },
    //签收
    toSee(item) {
      item.id = item.associationId
      if (item.id) {
        trainingUpdateSign(item.id,item.deptId,item.userId).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "签收成功！",
            });
            this.onLoad(this.page)
          }
        });
      }
    },
    view(item){
      item.id = item.associationId
      if(item.trainWay == 3){
        this.$router.push({
          path: "/business/safetyenment/train/trainingTaskListPageAdd",
          query: {
            type: "view",
            id: item.associationId,
            userId:item.userId,
            deptId:item.deptId,
            condition:item.status,
          },
        });
      }
      // 1:线上跳转到再教育
      if(item.trainWay == 1){
        // this.$router.push({
        //   path: '/business/training/reEducationSetting/view',
        //   query: {
        //     row: JSON.stringify(item),
        //     type: 'view',
        //   }
        // });
        this.$router.push({
          path: "/business/safetyenment/train/trainingEducation/form",
          query: {
            id: item.id
          },
        });
      }
      //2:线下跳转到场景
      if(item.trainWay == 2){
        this.$router.push({
          path: '/business/knowledge/onTheJobTrainingScene/add',
          query: {
            row: JSON.stringify(item),
            type: 'view',
          }
        });
      }
    },
    toView(item) {
      item.id = item.associationId
      if(item.trainWay == 3){
        this.$router.push({
          path: "/business/safetyenment/train/trainingTaskListPageAdd",
          query: {
            type: "view",
            id: item.id,
            userId:item.userId,
            deptId:item.deptId,
            condition:item.status,
          },
        });
      }
      // 1:线上跳转到再教育
      if(item.trainWay == 1){
        // this.$router.push({
        //   path: '/business/training/reEducationSetting/view',
        //   query: {
        //     row: JSON.stringify(item),
        //     type: 'view',
        //   }
        // });
        this.$router.push({
          path: "/business/safetyenment/train/trainingEducation/form",
          query: {
            id: item.id
          },
        });
      }
      //2:线下跳转到场景
      if(item.trainWay == 2){
        this.$router.push({
          path: '/business/knowledge/onTheJobTrainingScene/add',
          query: {
            row: JSON.stringify(item),
            type: 'view',
          }
        });
      }
    },
    //签到
    toDel(item) {
      if (item.id) {
        trainingUpdateSign({id: item.id, status: 3}).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "签到成功！",
            });
            this.onLoad(this.page)
          }
          // this.$router.push("/business/safetyenment/train/trainingUseTaskPage");
        });
      }
    },
    /**
     * 开始培训
     * @param item
     */
    startTraining(item) {
      this.videoShow = true;
      this.$nextTick(() => {
        console.log(this.$refs.VideoDialog, '426')
        this.$refs.VideoDialog.id = item.id;
        this.$refs.VideoDialog.getTrainCourseList();
      })
    },
    //完成培训
    finishTraining(item) {
      if (item.id) {
        trainingTaskUseStatusUpdate({id: item.id, status: 4}).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "操作成功！",
            });
            this.onLoad(this.page)
          }
        });
      }
    },

    addFn() {
      this.$router.push({
        path: "/business/safetyenment/train/DeptGroupAdd",
        query: {
          type: "add",
        },
      });
    },
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    //执行和删除

    SureToExecute() {
      alert("签到")
    },
    // 跳转到考试界面
    toExam(row) {
      this.$router.push({
        path: '/safetyenment/train/myExam/eduexampaper',
        query: {
          row: JSON.stringify({
            id: row.userExamId,
            paperId: row.examPaperId,
            examId: row.userExamId,
            type: 'edit',
            trainingId: row.id
          }),
        }
      });
    },
    toMyExam(row) {
      this.$router.push({
        path: '/safetyenment/train/myExam/eduuserexam',
        query: {
          id: row.userExamId,
        },
      });
    },
  },
  mounted() {
    const taskId = this.$route.query.taskId;
    this.userId = this.$route.query.userId;
    this.deptId = this.$route.query.deptId;
    this.taskCode = taskId;
    this.onLoad(this.page);
  },
};
</script>
<style scoped>
.headers {
  background: #ffffff;
  padding: 14px 12px;
}

.headers ::v-deep .el-button {
  height: 28px;
}
</style>
